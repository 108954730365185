<template>
  <section id="dashboard-ecommerce">
    <h3>{{ $t("Robôs em operação") }}</h3>
    <div v-if="data.robots && data.robots.length == 0">{{ $t('Você ainda não adquiriu nenhum robô de trading') }}</div>

    <b-row>
      <!-- Individually Trading Card -->
      <b-col lg="4" md="6" v-for="item in data.robots" :key="item.id">
        <individually-trading-card :item="item" :user-licenses="userLicenses" @withdraw="(val) => withdraw(val)" @reloadRobots="(val) => { initialData(); myRobotsCopyTrade(); }" />
      </b-col>
      <!--/ Individually Trading Card -->
    </b-row>

    <b-modal id="modal-my-results" :title="withdrawInfo && (withdrawInfo.type === 'bonus' || withdrawInfo.type === 'bonus-in-pyx') ? $t('Saque de bônus') : $t('Saque do rendimento')" centered ok-only :ok-title="isLoading ? $t('Aguarde...') : $t('Confirmar retirada')"
      @ok="handleOk" no-close-on-backdrop>
      <div v-if="withdrawInfo && withdrawInfo.item">        
        <div class="d-flex justify-content-between pt-1">
          {{ $t("Valor disponível para saque") }}:
          <span class="text-info">
            {{
              moneyFormat((withdrawInfo.type === 'bonus' || withdrawInfo.type === 'bonus-in-pyx') ? withdrawInfo.item.bonus - withdrawInfo.item.totalWhitdraw : withdrawInfo.item.totalProfit - withdrawInfo.item.totalIncomeWithdrawal)
            }}</span>
        </div>
      </div>
      <div v-if="withdrawInfo && withdrawInfo.item" class="mt-1">
        <div class="mb-1">
          {{ $t("Quanto do valor disponível você deseja retirar?") }}
        </div>       
          <b-form-input
            id="allocation-value"
            v-model="whitdrawValue"
            name="allocation-value"
            placeholder="$0.00"
            class="mt-1"      
          />
          <div v-if="withdrawInfo.type !== 'income-in-pyx' && withdrawInfo.type !== 'bonus-in-pyx'">
            <div v-if="whitdrawValue <= (withdrawInfo.type === 'bonus' ? (withdrawInfo.item.bonus - withdrawInfo.item.totalWhitdraw) : (withdrawInfo.item.totalProfit - withdrawInfo.item.totalIncomeWithdrawal))">
              <div class="mt-1 text-center">
                {{ $t("Taxa de retirada") }}:
                <span class="text-danger">5%</span>
              </div>
              <div class="text-center">
                {{ $t("Será creditado em seu saldo") }}:
                <span class="text-info">{{
                  moneyFormat(whitdrawValue * 0.95)
                }}</span>
              </div>
            </div>
            <div v-else class="text-danger text-center mt-1">
              {{ $t('Não há saldo suficiente para um saque deste valor') }}
            </div>
          </div>
      </div>
      <alert :text="textAlert" :color="colorAlert" :showAlert="showAlert"
        @changeVariableAlert="(val) => (showAlert = val)" class="mt-1" />
    </b-modal>
  </section>
</template>

<script>
import { BRow, BCol } from "bootstrap-vue";

import MyResultsMedal from "./MyResultsMedal.vue";
import MyResultsStatistics from "./MyResultsStatistics.vue";
import MyResultsMeetup from "./MyResultsMeetup.vue";
import MyResultsBrowserStates from "./MyResultsBrowserStates.vue";
import MyResultsGoalOverview from "./MyResultsGoalOverview.vue";
import IndividuallyTradingCard from "./IndividuallyTradingCard.vue";
import { mapGetters } from "vuex";
import SliderModalMyResults from "./SliderModalMyResults.vue";
import CopyTrades from "../trading-robot/copy-trades/CopyTrades.vue";

import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  components: {
    BRow,
    BCol,

    MyResultsMedal,
    MyResultsStatistics,
    MyResultsMeetup,
    MyResultsBrowserStates,
    MyResultsGoalOverview,
    IndividuallyTradingCard,

    SliderModalMyResults,

    CopyTrades,
  },
  computed: {
    ...mapGetters(["returnUser"]),
  },
  data() {
    return {
      showAlert: false,
      textAlert: "",
      colorAlert: "warning",
      data: {},
      modalData: {},
      whitdrawValue: 0,
      percentWithdraw: 0,
      referenceBonus: 0,
      unilevelBonusL2: 0,
      unilevelBonusL3: 0,
      unilevelBonusL4: 0,
      unilevelBonusL5: 0,
      unilevelBonusL6: 0,
      binaryBonus: 0,
      blockedBonus: 0,
      loading: true,
      myRobots: null,
      openModal: false,
      withdrawInfo: null,
      isLoading: false,
      userLicenses: [],
    };
  },
  mounted() {
    this.initialData();
    this.myRobotsCopyTrade();
    this.getUserLicenses();
  },
  methods: {
    myRobotsCopyTrade() {
      this.$store.dispatch("dashboardMyResults").then((response) => {
        this.myRobots = parseInt(response.botsRunning);
        this.loading = false;
      });
      this.loadMyResults();
    },
    initialData() {
      this.referenceBonus = this.returnUser.referenceBonus
        ? this.returnUser.referenceBonus
        : 0;
      this.unilevelBonusL2 = this.returnUser.unilevelBonusL2
        ? this.returnUser.unilevelBonusL2
        : 0;
      this.unilevelBonusL3 = this.returnUser.unilevelBonusL3
        ? this.returnUser.unilevelBonusL3
        : 0;
      this.unilevelBonusL4 = this.returnUser.unilevelBonusL4
        ? this.returnUser.unilevelBonusL4
        : 0;
      this.unilevelBonusL5 = this.returnUser.unilevelBonusL5
        ? this.returnUser.unilevelBonusL5
        : 0;
      this.binaryBonus = this.returnUser.binaryBonus
        ? this.returnUser.binaryBonus
        : 0;
      this.blockedBonus = this.returnUser.blockedBonus
        ? this.returnUser.blockedBonus
        : 0;
      this.whitdrawValue = 0;
      this.percentWithdraw = 0;
      this.data = {
        generalInfo: {
          acquiredRobots: 0,
          runningRobots: 0,
          initialValue: 0,
          totalProfit: 0,
          bonus: 0,
          totalInWithdrawals: 0,
          valueInFees: 0,
          currentValue: 0,
        },
        congratulations: {
          name: "",
          patrimony: 0,
        },
        botsRunning: 0,
        statisticsItems: [
          {
            icon: "ActivityIcon",
            color: "light-primary",
            value: 0,
            subtitle: "Trades",
            customClass: "mb-2 mb-xl-0",
          },
          {
            icon: "TrendingUpIcon",
            color: "light-info",
            value: 0,
            subtitle: "Com lucro",
            customClass: "mb-2 mb-xl-0",
          },
          {
            icon: "TrendingDownIcon",
            color: "light-danger",
            value: 0,
            subtitle: "Com prejuízo",
            customClass: "mb-2 mb-sm-0",
          },
          {
            icon: "DollarSignIcon",
            color: "light-success",
            value: 0,
            subtitle: "Resultado",
            customClass: "",
          },
        ],
        meetup: {
          mediaData: [
            {
              avatar: "DollarSignIcon",
              title: this.moneyFormat(this.referenceBonus),
              subtitle: "recebido de bônus dos indicados diretos",
              color: "success",
            },
            {
              avatar: "DollarSignIcon",
              title: this.moneyFormat(
                this.unilevelBonusL2 +
                this.unilevelBonusL3 +
                this.unilevelBonusL4 +
                this.unilevelBonusL5
              ),
              subtitle: "recebido de bônus dos indicados indiretos",
              color: "success",
            },
            {
              avatar: "DollarSignIcon",
              title: this.moneyFormat(
                this.binaryBonus
              ),
              subtitle: "recebido de bônus da árvore binária",
              color: "success",
            },
            {
              avatar: "DollarSignIcon",
              title: this.moneyFormat(this.blockedBonus),
              subtitle: "bônus bloqueado",
              color: "danger",
            },
          ],
          avatars: [
            {
              avatar: require("@/assets/images/portrait/small/avatar-s-9.jpg"),
              fullName: "Billy Hopkins",
            },
            {
              avatar: require("@/assets/images/portrait/small/avatar-s-6.jpg"),
              fullName: "Amy Carson",
            },
            {
              avatar: require("@/assets/images/portrait/small/avatar-s-8.jpg"),
              fullName: "Brandon Miles",
            },
            {
              avatar: require("@/assets/images/portrait/small/avatar-s-7.jpg"),
              fullName: "Daisy Weber",
            },
            {
              avatar: require("@/assets/images/portrait/small/avatar-s-20.jpg"),
              fullName: "Jenny Looper",
            },
          ],
        },
        goalOverview: {
          initialValue: 0,
          currentValue: 0,
          licenseValue: 0,
          series: [0],
        },
        robots: []
      };
    },
    loadMyResults() {
      this.data.congratulations.name = this.returnUser.username;
      this.$store.dispatch("dashboardMyResults").then((response) => {
        this.data.congratulations.patrimony = response.patrimony;
        this.data.statisticsItems[0].value = response.totalTrades;
        this.data.statisticsItems[1].value = response.tradesGain;
        this.data.statisticsItems[2].value = response.tradesLose;
        this.data.statisticsItems[3].value = this.moneyFormat(response.totalProfit);
        this.data.botsRunning = response.botsRunning;
        this.data.goalOverview.initialValue = this.moneyFormat(response.initialValue);
        this.data.goalOverview.licenseValue = this.moneyFormat(response.licenseValue);
        this.data.goalOverview.currentValue = this.moneyFormat(response.currentValue);
        this.data.goalOverview.series = [
          parseFloat(
            (response.totalProfit / response.limitIncome) * 100
          ).toFixed(2),
        ]; 
        this.data.robots = response.arrayRobots;
        this.data.generalInfo.acquiredRobots = response.arrayRobots.length;
        this.data.generalInfo.runningRobots = response.arrayRobots.length;
        this.data.generalInfo.initialValue = response.initialValue;
        this.data.generalInfo.totalProfit = response.totalProfit;
        this.data.generalInfo.bonus = response.bonus;
        this.data.generalInfo.totalWhitdraw = response.totalWhitdraw;
        this.data.generalInfo.valueInFees = response.valueInFees;
        this.data.generalInfo.currentValue = response.currentValue;
      });
    },
    handleOk(bvModalEvt) {
      bvModalEvt.preventDefault();

      this.withdrawExecute();
    },
    withdraw(element) {
      this.withdrawInfo = element
      this.$bvModal.show("modal-my-results");
    },
    withdrawExecute() {
      if(!this.isLoading) {
        this.isLoading = true;
        const element = this.withdrawInfo;
        const data = {
          robotId: element.item._id,
          value: this.whitdrawValue,
          type: element.type
        };
        if (data.value > 0) {
          this.$store
            .dispatch("withdrawValueTrading", data)
            .then((resp) => {              
              this.showAlert = true;
              this.textAlert = resp.message;
              this.colorAlert = "success";
              this.isLoading = false;
              this.initialData();
              this.loadMyResults();
              setTimeout(() => {
                this.$bvModal.hide("modal-my-results");
              }, 3000)
            })
            .catch((error) => {
              this.showAlert = true;
              this.textAlert = error.message;
              this.colorAlert = "danger";
              this.isLoading = false;
            });
        } else {
          this.showAlert = true;
          this.textAlert = "Valor para retirada inválido.";
          this.colorAlert = "danger";
          this.isLoading = false;
        }
      }      
    },
    openModalMyResults(val) {
      this.modalData = val;
      this.whitdrawValue = 0;
      this.percentWithdraw = 0;
      this.$bvModal.show("modal-my-results");
    },
    getUserLicenses() {
      this.$store
        .dispatch("getUserLicense")
        .then((resp) => {
          this.userLicenses = resp;
        });
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/pages/dashboard-ecommerce.scss";
@import "@core/scss/vue/libs/chart-apex.scss";
@import "@core/scss/vue/libs/vue-slider.scss";
</style>
